.retailer-modal-project-publish-confirmation{
    width: 100%; // Default width for small screens
    max-width: 300px; // Maximum width on smaller screens
 
    @media (min-width: 640px) { // For screens 640px and larger
        max-width: 300px;
    }
    @media (min-width: 768px) { // For screens 768px and larger
        max-width: 400px;
    }
    @media (min-width: 1024px) { // For screens 1024px and larger
        max-width: 500px;
    }
    .ant-modal-content{
        background: var(--retailer-modal-background);
        opacity: 0.96;
        padding: 30px;
        backdrop-filter: blur(4px);
        .ant-modal-close-x{
            img{
                filter: var(--svg-filter-invert);
            }
        }
        .text-project-name{
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 26.667px;
            color: var(--normal-text-color);
            text-align: center;
        }
        .text-description{
            color: #6F6F6F;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
        }
        .input-project-name{
            border-radius: 5px;
            border: 1px solid #BFBFBF;
            background: transparent;
            color: var(--normal-text-color);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &::placeholder{
                color: var(--retailer-header-search-placeholder-color);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .btn-save{
            padding: 7px 59px;
            border-radius: 5px;
            background: var(--retailer-button-background-light);
            color: #000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26.667px;
        }
    }
}