.scrollbar-hide::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
  }
  
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;    /* Firefox */
    overflow: auto;           /* Ensure scrolling still works */
  }

  .swiper-pagination-bullet {
    background-color: #D9D9D9 !important;
    opacity: 1 !important;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #000000 !important;
  }