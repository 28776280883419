.canvas-container{
    flex: auto;
}
.sidebar-editor-tooltip{
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.preview-exit-panel{
    z-index: 2;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
    &.desktop{
        position: absolute;
        top: 130px;
        right: 36px;
    }
    &.mobile{
        position: absolute;
        &.landscape{
            top: 70px;
            right: 40px;
        }
        &.portrait{
            top: 180px;
            right: 40px;
        }
    }
    span{
        margin-top: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--normal-text-color);
    }
}
.btn-exit-preview-mode{
    position: relative;
    // right: 40px;
    // top: 40px;
    user-select: none;
    z-index: 2;
    padding: 12px 0;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--normal-text-color);
    z-index: 1;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    width: 136px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px; 
        border: 2px solid transparent;
        background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%) border-box;
        mask:
          linear-gradient(#fff 0 0) padding-box, 
          linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}
.pointing-down-arrow{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
}

.nipple-wrapper {
    position: fixed;
    bottom: 0;
    left: 0px;
    background: none;
    width: 120px;
    height: 120px;
    z-index: 5;
}
#nippleContainerId{
    .front{
        background: radial-gradient(rgb(214, 214, 214), rgb(140, 140, 140) 30%, rgb(0, 0, 0) 60%) white !important;
        border: solid 3px #FFFFFF;
    }
    .back{
        background: radial-gradient(closest-side, #5f3a3a, #3e3e3e, #e8e8e8) !important;
        border: solid 2px #FFFFFF;
    }
}
.project-mode-control-higher{
    position: absolute;
    bottom: 78px;
    right: 38px;

    display: flex;
    align-items: center;
    gap: 24px;
    z-index: 2;
    .btn-preview{
        padding: 10px 25px;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        gap: 11px;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        user-select: none;
    }
}
@keyframes spin{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.project-name-container{
    position: absolute;
    top: 28px;
    right: 39px;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 25px;
    .project-name-input{
        width: 200px;
        height: 40px;
        border: none;
        outline: none;
        border-radius: 0;
        border-bottom: solid 1px #000000;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.17) 100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;

        color: #FFF;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.text-project-name{
    font-family: Inter;
    font-size: 36px;
    font-weight: 900;
    line-height: 43.57px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 2px 0px #00000040;
}
.text-project-description{
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    background: #FFFFFF1F;
    border: 1px solid #FFFFFFB8;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: center;
    text-shadow: 0px 1px 4px 0px #00000099;
    color: #FFFFFF;
    max-width: calc(80%);
}
.loading-screen-progress{
    margin-right: 0;
    .ant-progress-outer{
        height: 12px !important;
        .ant-progress-bg{
            height: 12px !important;
            border: solid 1px #FFFFFF;
        }
    }
}
.loading-screen-progress-tooltip{
    position: absolute;
    bottom: 35px;
    left: var(--persent);
    white-space: nowrap;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #515151;
    transform: translateX(-50%);

    background: #D9D9D9;
    border-radius: 5.23px;
    padding: 6px 12px;
    &::after{
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 7%);
        transform: rotate(45deg) translateX(50%) translateY(50%);
        background: #D9D9D9;
    }
}
.moving-guide-button-container{
    position: absolute;
    bottom: 28px;
    left: 35px;
    z-index: 6;
    user-select: none;
}

.editor-mode-loading-screen-wrapper{
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1.0);
    &:has(.loading-screen-animation){
        animation-name: hiddenLoadingEndAnimation;
        animation-delay: 2s;
        animation-duration: 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    .editor-mode-loading-screen{
        overflow: hidden;
        .background-image{
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            height: 100%;
            object-fit: cover;
        }
        &.loading-screen-animation{
            animation-name: loadingEndAnimation;
            animation-delay: 2s;
            animation-duration: 3s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }
}


@keyframes loadingEndAnimation {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    60% {
        transform: scale(1.5);
        opacity: 0;
        display: none;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
        display: none;
    }
}

@keyframes hiddenLoadingEndAnimation {
    0% {
        background-color: rgba(255, 255, 255, 1.0);
    }
    60% {
        background-color: rgba(255, 255, 255, 1.0);
    }
    100% {
        background-color: rgba(255, 255, 255, 0);
        display: none;
    }
}




// .canvas-container {
//     flex: auto;
// }

// .sidebar-editor-tooltip {
//     color: #FFF;
//     font-family: "Inter", sans-serif;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: normal;
// }

// .preview-exit-panel {
//     z-index: 998;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     user-select: none;

//     &.desktop {
//         position: absolute;
//         top: 130px;
//         right: 36px;
//     }

//     &.mobile {
//         position: absolute;

//         &.landscape {
//             top: 70px;
//             right: 40px;
//         }

//         &.portrait {
//             top: 180px;
//             right: 40px;
//         }
//     }

//     span {
//         margin-top: 4px;
//         font-family: 'Inter', sans-serif;
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 19px;
//         color: var(--normal-text-color);
//     }
// }

// .btn-exit-preview-mode {
//     position: relative;
//     user-select: none;
//     z-index: 999; /* Ensure it's always on top */
//     padding: 12px 0;
//     border-radius: 8px;
//     font-family: 'Inter', sans-serif;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 19px;
//     color: var(--normal-text-color);
//     display: flex;
//     gap: 7px;
//     align-items: center;
//     justify-content: center;
//     width: 136px;
//     pointer-events: auto; /* Ensure this button is clickable */

//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         border-radius: 8px;
//         border: 2px solid transparent;
//         background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%) border-box;
//         mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
//         mask-composite: exclude;
//     }
// }

// .pointing-down-arrow {
//     position: absolute;
//     top: 0;
//     left: 0;
//     transform: translateY(-100%);
// }

// .nipple-wrapper {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     background: none;
//     width: 120px;
//     height: 120px;
//     z-index: 999;
//     pointer-events: auto; /* Ensure joystick is interactive */
// }

// #nippleContainerId .front {
//     background: radial-gradient(rgb(214, 214, 214), rgb(140, 140, 140) 30%, rgb(0, 0, 0) 60%) white !important;
//     border: solid 3px #FFFFFF;
// }

// #nippleContainerId .back {
//     background: radial-gradient(closest-side, #5f3a3a, #3e3e3e, #e8e8e8) !important;
//     border: solid 2px #FFFFFF;
// }

// .project-mode-control-higher {
//     position: absolute;
//     bottom: 78px;
//     right: 38px;
//     display: flex;
//     align-items: center;
//     gap: 24px;
//     z-index: 2;

//     .btn-preview {
//         padding: 10px 25px;
//         background: rgba(0, 0, 0, 0.5);
//         display: flex;
//         gap: 11px;
//         border-radius: 12px;
//         color: #FFF;
//         font-family: 'Inter', sans-serif;
//         font-size: 16px;
//         font-weight: 500;
//         user-select: none;
//         pointer-events: auto; /* Ensure buttons are clickable */
//     }
// }

// @keyframes spin {
//     from {
//         transform: rotate(0deg);
//     }
//     to {
//         transform: rotate(360deg);
//     }
// }

// .project-name-container {
//     position: absolute;
//     top: 28px;
//     right: 39px;
//     z-index: 3;
//     display: flex;
//     align-items: center;
//     gap: 25px;

//     .project-name-input {
//         width: 200px;
//         height: 40px;
//         border: none;
//         outline: none;
//         border-bottom: solid 1px #000;
//         background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.17) 100%);
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-color: transparent;
//         color: #FFF;
//         font-family: 'Inter', sans-serif;
//         font-size: 16px;
//         font-weight: 600;
//     }
// }

// .text-project-name {
//     font-family: 'Inter', sans-serif;
//     font-size: 36px;
//     font-weight: 900;
//     text-align: center;
//     color: #FFF;
//     text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
// }

// .text-project-description {
//     padding: 10px 20px;
//     border-radius: 10px;
//     background: rgba(255, 255, 255, 0.12);
//     border: 1px solid rgba(255, 255, 255, 0.72);
//     font-family: 'Inter', sans-serif;
//     font-size: 18px;
//     font-weight: 400;
//     text-align: center;
//     color: #FFF;
//     text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
//     max-width: 80%;
// }

// .loading-screen-progress {
//     margin-right: 0;

//     .ant-progress-outer {
//         height: 12px !important;

//         .ant-progress-bg {
//             height: 12px !important;
//             border: solid 1px #FFF;
//         }
//     }
// }

// .loading-screen-progress-tooltip {
//     position: absolute;
//     bottom: 35px;
//     left: var(--persent);
//     white-space: nowrap;
//     font-family: 'Inter', sans-serif;
//     font-size: 14px;
//     font-weight: 400;
//     color: #515151;
//     transform: translateX(-50%);
//     background: #D9D9D9;
//     border-radius: 5px;
//     padding: 6px 12px;

//     &::after {
//         content: '';
//         width: 10px;
//         height: 10px;
//         position: absolute;
//         bottom: 0;
//         left: 50%;
//         transform: rotate(45deg) translate(-50%, 50%);
//         background: #D9D9D9;
//     }
// }

// .moving-guide-button-container {
//     position: absolute;
//     bottom: 28px;
//     left: 35px;
//     z-index: 6;
//     user-select: none;
// }

// .editor-mode-loading-screen-wrapper {
//     overflow: hidden;
//     background-color: rgba(255, 255, 255, 1.0);

//     &:has(.loading-screen-animation) {
//         animation-name: hiddenLoadingEndAnimation;
//         animation-delay: 2s;
//         animation-duration: 3s;
//         animation-fill-mode: forwards;
//     }

//     .editor-mode-loading-screen {
//         overflow: hidden;

//         .background-image {
//             width: 100%;
//             position: absolute;
//             top: 0;
//             left: 0;
//             z-index: 0;
//             height: 100%;
//             object-fit: cover;
//         }

//         &.loading-screen-animation {
//             animation-name: loadingEndAnimation;
//             animation-delay: 2s;
//             animation-duration: 3s;
//             animation-fill-mode: forwards;
//         }
//     }
// }

// @keyframes loadingEndAnimation {
//     0% {
//         transform: scale(1);
//         opacity: 1;
//     }
//     60% {
//         transform: scale(1.5);
//         opacity: 0;
//     }
//     100% {
//         transform: scale(1.5);
//         opacity: 0;
//     }
// }

// @keyframes hiddenLoadingEndAnimation {
//     0% {
//         background-color: rgba(255, 255, 255, 1);
//     }
//     60% {
//         background-color: rgba(255, 255, 255, 1);
//     }
//     100% {
//         background-color: rgba(255, 255, 255, 0);
//     }
// }

// @supports (-webkit-touch-callout: none) {
//     /* iOS-specific fixes */

//     .nipple-wrapper {
//         z-index: 999; /* Ensure high z-index to fix touch issues */
//     }

//     .preview-exit-panel {
//         top: 120px;
//         right: 20px;
//     }
// }
//